import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { List, Avatar, Collapse, Empty } from 'antd';

import SEO from '../blocks/layout/seo';
import AppContainer from '../blocks/layout';

import { Firebase } from '../config/firebase';
import { GlobalStateContext } from '../context/GlobalContextProvider';
import { groupAccountsData, getAppName } from '../config/custom/functions';
import OAuthOptions from '../blocks/components/oauthOptions';

const { Panel } = Collapse;

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
// markup
const Accounts = () => {
      const state = useContext(GlobalStateContext);
      const [loading, setLoading] = useState(false);
      const [accounts, setAccounts] = useState([]);

      useEffect(() => {
            setLoading(true);
            if (state.selectedProjectId) {
                  Firebase.auth().onAuthStateChanged((user) => {
                        if (user) {
                              const data = JSON.stringify({
                                    projectId: state.selectedProjectId,
                                    uid: user.uid,
                              });
                              const config = {
                                    method: 'post',
                                    url: `${process.env.GATSBY_BASE_URL}/auth/accounts`,
                                    headers: {
                                          'Content-Type': 'application/json',
                                    },
                                    data,
                              };

                              axios(config)
                                    .then(function (response) {
                                          setLoading(false);
                                          // setAccounts(response.data);
                                          setAccounts(
                                                groupAccountsData(response.data)
                                          );
                                    })
                                    .catch(function (error) {
                                          console.log(error);
                                    });
                        }
                  });
            }
      }, [state.selectedProjectId]);

      function callback(key) {
            console.log(key);
      }
      return (
            <>
                  <SEO title="Accounts — NoCodeAPI" />
                  <AppContainer activeNav="accounts">
                        {Object.keys(accounts).length > 0 ? (
                              <>
                                    <div className="page-content-header">
                                          <h2>Accounts</h2>
                                          <p>All your OAuth accounts list.</p>
                                    </div>
                                    <Collapse
                                          defaultActiveKey={['0']}
                                          onChange={callback}
                                    >
                                          {Object.keys(accounts).map(
                                                (keyName, i) => (
                                                      <Panel
                                                            header={getAppName(
                                                                  keyName
                                                            )}
                                                            key={i}
                                                      >
                                                            <List
                                                                  itemLayout="horizontal"
                                                                  className="accounts-list"
                                                                  dataSource={
                                                                        accounts[
                                                                              keyName
                                                                        ]
                                                                  }
                                                                  renderItem={(
                                                                        item
                                                                  ) => (
                                                                        <List.Item>
                                                                              <List.Item.Meta
                                                                                    avatar={
                                                                                          <Avatar
                                                                                                shape="square"
                                                                                                src={
                                                                                                      item.userInfo ===
                                                                                                      undefined
                                                                                                            ? `/logos/${item.app_id}.png`
                                                                                                            : item
                                                                                                                    .userInfo
                                                                                                                    .picture
                                                                                                }
                                                                                          />
                                                                                    }
                                                                                    title={
                                                                                          item.userInfo !==
                                                                                          undefined
                                                                                                ? item
                                                                                                        .userInfo
                                                                                                        .name
                                                                                                : item.name
                                                                                    }
                                                                                    description={moment(
                                                                                          item.updated_date
                                                                                    ).fromNow()}
                                                                              />
                                                                              {state.projects?.find(
                                                                                    (
                                                                                          p
                                                                                    ) =>
                                                                                          p._id ==
                                                                                          state.selectedProjectId
                                                                              )
                                                                                    ?.isOwner && (
                                                                                    <div>
                                                                                          <OAuthOptions
                                                                                                item={
                                                                                                      item
                                                                                                }
                                                                                          />
                                                                                    </div>
                                                                              )}
                                                                        </List.Item>
                                                                  )}
                                                            />
                                                      </Panel>
                                                )
                                          )}
                                    </Collapse>
                              </>
                        ) : (
                              <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description="There is no OAuth account added. Go Marketplace to start."
                              />
                        )}
                  </AppContainer>
            </>
      );
};

export default Accounts;
