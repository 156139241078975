import React, { useState } from 'react';
import axios from 'axios';
import { Button, message } from 'antd';
import { RiDeleteBinLine } from 'react-icons/ri';

import { Firebase } from '../../config/firebase';
import { getAppName } from '../../config/custom/functions';

const OAuthOptions = ({ item }) => {
      const deleteConnection = () => {
            const { name, app_id } = item;
            const r = window.confirm(
                  `You want to delete ${getAppName(app_id)} - ${name} account?`
            );
            if (r === true) {
                  Firebase.auth().onAuthStateChanged((user) => {
                        if (user) {
                              const data = JSON.stringify({
                                    uid: user.uid,
                                    _id: item._id,
                              });
                              const config = {
                                    method: 'delete',
                                    url: `${process.env.GATSBY_BASE_URL}/auth/accounts`,
                                    headers: {
                                          'Content-Type': 'application/json',
                                    },
                                    data,
                              };

                              axios(config)
                                    .then(function (response) {
                                          window.location.reload();
                                    })
                                    .catch(function (error) {
                                          console.log(error);
                                    });
                        }
                  });
            } else {
                  console.log('cancel');
            }
      };

      const cancel = (e) => {
            message.error('Click on No');
      };

      return (
            <Button>
                  <RiDeleteBinLine onClick={deleteConnection} />
            </Button>
      );
};

export default OAuthOptions;
